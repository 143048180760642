import { onBeforeUnmount, onMounted } from 'vue';

export function useKeyEvents({
  onKeyUp,
  onKeyDown,
}: {
  onKeyUp?: (e: KeyboardEvent) => void;
  onKeyDown?: (e: KeyboardEvent) => void;
}) {
  const handleKeyUp = (e: KeyboardEvent) => {
    if (onKeyUp) onKeyUp(e);
  };

  const handleKeyDown = (e: KeyboardEvent) => {
    if (onKeyDown) onKeyDown(e);
  };

  onMounted(() => {
    if (onKeyUp) window.addEventListener('keyup', handleKeyUp);
    if (onKeyDown) window.addEventListener('keydown', handleKeyDown);
  });

  onBeforeUnmount(() => {
    if (onKeyUp) window.removeEventListener('keyup', handleKeyUp);
    if (onKeyDown) window.removeEventListener('keydown', handleKeyDown);
  });
}
